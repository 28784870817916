/**
 * Material design components
 * 
 * Declarando instancias de clases para interacción con componentes.
 */
// ...ripple effect
import { MDCRipple } from '@material/ripple';
document.querySelectorAll('.mdc-button').forEach(e => new MDCRipple(e));
document.querySelectorAll('.mdc-fab').forEach(e => new MDCRipple(e));
document.querySelectorAll('.mdc-ripple-surface').forEach(e => new MDCRipple(e));
// ...textfields
import { MDCTextField } from '@material/textfield';
const newsletterEmail:MDCTextField = new MDCTextField(document.querySelector('.mdc-text-field') as HTMLElement);
// ...chips
import { MDCChipSet } from '@material/chips';
const navigatorChipSet = new MDCChipSet(document.getElementById('chip-navigator') as HTMLElement);
// ...menu
import { MDCMenu, MDCMenuItemEvent } from '@material/menu';
const navigatorOptionsMenu = new MDCMenu(document.querySelector('.mdc-menu') as HTMLElement);

/**
 * Otros elementos del DOM
 */
const sectionServices = document.querySelector('.section--services') as HTMLElement;
const sectionSolutions = document.querySelector('.section--solutions') as HTMLElement;
const sectionTestimonials = document.querySelector('.section--testimonials-header') as HTMLElement;
const sectionContact = document.querySelector('.section--contact') as HTMLElement;

/**
 * Creando controller para remover todos los listeners levantados cuando la página
 * se destruya.
 */
const controller = new AbortController();

// Método onDestroy del window (página cerrada)
window.addEventListener('beforeunload', () => {

    // Removiendo listeners
    controller.abort();

    return null;
}, { signal: controller.signal });

// ##########################################################################
// Chip page navigator ######################################################
// ##########################################################################

/**
 * Listener para funcionalidad de chipset que despliega el menú de navegación.
 */
navigatorChipSet.listen('MDCChipSet:interaction', event => {

    navigatorOptionsMenu.open = true;

}, { signal: controller.signal });

/**
 * Evento para trasladar la página en el eje Y según la opción seleccionada
 * del menú de navegación.
 */
navigatorOptionsMenu.listen('MDCMenu:selected', (event:MDCMenuItemEvent) => {
    
    const index = event.detail.index;
    let position:number = 0;

    switch (index) {
        case 0: // Servicios
            position = getAxisYPosition(sectionServices);
        break;
        case 2: // Soluciones
            position = getAxisYPosition(sectionSolutions);
        break;
        case 3: // Testimonios
            position = getAxisYPosition(sectionTestimonials);
        break;
        case 4: // Contacto
            position = getAxisYPosition(sectionContact);
        break;
    }

    // Colocando el scroll de la página en el elemento calculado
    window.scrollTo(0, position);
    
}, { signal: controller.signal });

/**
 * Método para obtener la posición del eje Y de un elemento del DOM en pixeles.
 */
function getAxisYPosition(element:HTMLElement):number {
    return element.getBoundingClientRect().top
    + window.scrollY
    - 70        // 70px es el height del header
    - 20;       // 20px es un extra de padding
}

// ##########################################################################
// Service selector #########################################################
// ##########################################################################

/**
 * Evento para manejar selector de servicios.
 * 
 * Adjuntando evento al objeto window, ya que Webpack no expone al objeto global
 * por diseño.
 */
(window as any).onServiceSelection = function (index:number) {
    // Modificando el elemento seleccionado mediante las clases de CSS
    const optionsElements = document.querySelectorAll('.service-selector--item');
    optionsElements.forEach(element => element.classList.remove('service-selector--selected-item'));
    optionsElements[index].classList.add('service-selector--selected-item');
    // Cambiando imagen del service selector
    const displayElement = document.querySelector('.image-toggler--list') as HTMLElement;
    switch (index) {
        case 0:
            displayElement.classList.add('itl--for-domestic-services');
            displayElement.classList.remove('itl--for-business-services');
        break;
        case 1:
            displayElement.classList.remove('itl--for-domestic-services');
            displayElement.classList.add('itl--for-business-services');
        break;
    }
}

// ##########################################################################
// Newsletter form ##########################################################
// ##########################################################################

newsletterEmail.listen('change', event => {
    console.log('cambia');
}, { signal: controller.signal });